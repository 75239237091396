/* Estilos generales para el cuerpo */
:root {
    --color-background-body: #f0f2f5;
    --color-border-input: #ddd;
    --color-background-button-login: #007bff;
    --color-background-button-login-hover: #0056b3;
    --color-h2: #333;
    --color-white: #ffffff;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: var(--color-background-body);
    font-family: 'Arial', sans-serif;
  }
  
  /* Contenedor del formulario de login */
  .login-container {
    background-color: var(--color-white);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  /* Estilos para los inputs */
  .login-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid var(--color-border-input);
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  /* Estilos para el botón */
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: var(--color-background-button-login);
    color: var(--color-white);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: var(--color-background-button-login-hover);
  }
  
  /* Estilos adicionales */
  h2 {
    margin-bottom: 20px;
    color: var(--color-h2);
    font-size: 24px;
  }

  #root {
    display: flex;
    width: auto;
  }
  