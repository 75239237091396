.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.app-container > div {
  width: 100%;
}

.main-content {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
}

.main-content > div {
  width: 100%;
}

.page-container {
  width: 100%;
  height: 100%;
  padding: var(--var-padding-20);
  background-color: #f4f4f4;
  overflow: scroll;
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: 700;
  margin: 0;
}

.container-metrics {
  display: flex;
  width: 100%;
  min-height: 150px;
  border: 1px solid var(--color-border);
}

.container-metrics .container-svg {
  width: 150px;
}

.container-metrics .background-green {
  background-color: var(--color-green);
}

.container-metrics .background-orange {
  background-color: var(--color-orange);
}

.container-metrics .container-svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-metrics h3, .container-grafica h3 {
  font-size: var(--font-size-h3);
  font-weight: 700;
}

.container-metrics h4, .container-grafica h4 {
  font-size: var(--font-size-h4);
}

.container-metrics h3, .container-metrics h4, .container-grafica h3, .container-grafica h4 {
  margin: 0;
}

.container-grafica {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-border);
  padding: var(--var-padding-20);
  gap: var(--var-gap-20);
}

.container-grafica canvas {
  max-width: 80%;
  max-height: 400px;
}

.container-nav-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--var-gap-40);
  width: 100%;
  min-height: 80px;
  border: 1px solid var(--color-border);
  padding: var(--var-padding-20);
}

.container-nav-search > div {
  width: calc(100% - 200px);
  display: flex;
  gap: var(--var-gap-20);
  align-items: center;
}

.container-nav-search button {
  width: 200px;
  padding: var(--var-padding-10);
  background-color: var(--color-green);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  font-size: var(--font-size-normal-high);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.container-nav-search input {
  max-width: 500px;
  width: 100%;
  padding: var(--var-padding-10);
  border: 1px solid var(--color-border-input);
  border-radius: 4px;
  font-size: var(--font-size-normal-high);
}

.container-nav-search label {
  font-size: var(--font-size-normal-high);
  color: var(--color-black);
  font-weight: 700;
}

.modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: var(--color-background-screen-black);
  z-index: 9999999;
  backdrop-filter: blur(3px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
	display: flex;
	flex-direction: column;

	background-color: var(--color-white);
	border-radius: 10px;
	padding: var(--var-padding-20);
	gap: var(--var-gap-40);

	width: 80%;
	max-width: 930px;

	max-height: 80%;
	overflow-x: hidden;
	overflow-y: auto;
}

.modal .flex-row, .modal .flex-column {
  width: 100%;
}

.modal form {
  display: flex;
  flex-direction: column;
  gap: var(--var-gap-40);
}

.modal input {
  width: 100%;
  padding: var(--var-padding-10);
  border: 1px solid var(--color-border-input);
  border-radius: 4px;
  font-size: var(--font-size-normal-high);
}

.modal select {
  width: 100%;
  padding: var(--var-padding-10);
  border: 1px solid var(--color-border-input);
  border-radius: 4px;
  font-size: var(--font-size-normal-high);
}

.modal label {
  font-size: var(--font-size-normal-high);
  color: var(--color-black);
  font-weight: 700;
}

.modal button {
  width: 200px;
  padding: var(--var-padding-10);
  background-color: var(--color-green);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  font-size: var(--font-size-normal-high);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal button.cancel {
  background-color: var(--color-gray);
}

.container-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--color-border);
  padding: var(--var-padding-20);
  gap: var(--var-gap-20);
}

.container-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.container-table tr {
  display: flex;
  width: 100%;
}

.container-table th {
  font-weight: 700;
}

.container-table td, .container-table th {
  display: flex;
  width: 100%;
  padding: var(--var-padding-10);
  border-bottom: 1px solid var(--color-border);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-table td button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-border-input);
  cursor: pointer;
}

.container-table td a {
  width: 100%;
  text-decoration: none;
  color: var(--color-black);
  cursor: pointer;
}

.container-table td b {
  font-weight: 700;
}

.container-table td .button-edit, .container-table td .button-delete, .container-table td .button-transparent {
  padding: 0px;
}

.container-table td .button-transparent {
  border: none;
}

.container-table td a:hover > div{
  font-weight: 700;
}

.container-table td button:hover path{
  fill: var(--color-black);
}

.container-table .button-edit {
  background-color: var(--color-orange);
}

.container-table .button-delete {
  background-color: var(--color-red);
}

.container-table .pagination {
  display: flex;
  justify-content: space-between;
  gap: var(--var-gap-10);
}

.container-table .pagination > div{
  display: flex;
  gap: var(--var-gap-10);
  align-items: center;
}

.container-table .pagination b {
  font-weight: 700;
}

.container-table .miniatura {
  max-width: 100px;
  max-height: 100px;
}

.grid-container-conf {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--var-gap-40);
}

.container-opt {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--color-border);
  padding: var(--var-padding-20);
  gap: var(--var-gap-20);
}

.container-opt .header-section {
  display: flex;
  justify-content: space-between;
  padding: var(--var-padding-10);
}

.container-opt h3 {
  font-size: var(--font-size-h3);
  font-weight: 700;
  margin: 0;
}

.container-opt .header-section button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-border-input);
  cursor: pointer;
  background-color: var(--color-black);
}

.container-opt .header-sectiond button:hover path{
  fill: var(--color-black);
}

.container-opt tr {
  display: flex;
  width: 100%;
}

.container-opt td {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--var-padding-10);
  border-bottom: 1px solid var(--color-border);
}

.container-opt td button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-border-input);
  cursor: pointer;
}

.container-table opt button:hover path{
  fill: var(--color-black);
}

.container-opt .button-delete, .container-opt .header-section button {
  padding: 0px;
}

.container-opt .button-delete {
  background-color: var(--color-red);
}

.label-required::after{
  content: '*';
  color: var(--color-red);
}

.signature-container button {
  width: 200px;
  padding: var(--var-padding-10);
  background-color: var(--color-green);
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  font-size: var(--font-size-normal-high);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signature-container button.cancel {
  background-color: var(--color-gray);
}

@media screen and (max-width: 500px){
  .flex-row:has(>button) {
    flex-wrap: nowrap;
  }

  .container-nav-search {
    flex-wrap: wrap;
  }

  .container-nav-search > div {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .pagination {
    flex-direction: column;
    align-items: center;
  }

  .grid-container-conf {
    grid-template-columns: repeat(1, 1fr);
  }
}