.navbar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px;
    color: var(--color-black);
    border-bottom: 1px solid var(--color-border);
  }

  .navbar-left {
    padding: 10px;
    max-height: 80px;
  }
  
  .navbar-left img {
    height: 60px;
  }
  
  .navbar-right:has(>.username) {
    width: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-items: center;
  }
  
  .username {
    margin-right: 10px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .dropdown-toggle {
    background: none;
    border: none;
    color: var(--color-black);
    cursor: pointer;
    font-size: 20px;
    line-height: 20px;
  }
  
  .dropdown-menu {
    width: 100%;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    background-color: var(--color-white);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border: 1px solid var(--color-border);
  }

  .dropdown-toggle.mobile {
    display: none;
  }
  
  .dropdown-menu button, .dropdown-menu a {
    display: block;
    padding: 10px;
    color: black;
    text-decoration: none;
    outline: none;
    border: none;
    width: 100%;
    cursor: pointer;
    background-color: var(--color-white);
    text-align: center;
  }
  
  .dropdown-menu button:hover, .dropdown-menu a:hover {
    background-color: #ddd;
  }

  #root {
    width: 100%;
  }

  .app {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 768px){
    .dropdown-toggle.desk {
      display: none;
    }

    .dropdown-toggle.mobile {
      display: block;
    }
  }
  