.signature-container {
    display: flex;
    flex-direction: column;
    gap: var(--var-gap-40);
}

.signature-container canvas {
    border: 1px solid var(--color-border)
}

.signature-container > div:has(canvas) {
    display: flex;
    justify-content: center;
}