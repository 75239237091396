.navbar-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    padding: 10px;
}

.navbar-bottom button, .navbar-bottom a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    outline: none;
    border: none;
    gap: var(--var-gap-10);
    cursor: pointer;
    text-decoration: none;
}

.navbar-bottom button svg path, .navbar-bottom a svg path {
    fill: var(--color-black);
}

.navbar-bottom button span, .navbar-bottom a span {
    color: var(--color-black);
    font-size: var(--font-size-h3);
}

.navbar-bottom button:hover span, .navbar-bottom a:hover span {
    font-weight: 700;
    color: var(--color-blue);
}

.navbar-bottom button:hover svg path, .navbar-bottom a:hover svg path {
    fill: var(--color-blue);
}
  