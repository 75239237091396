.sidebar {
    max-width: 250px;
    background-color:var(--color-white);
    color: var(--color-white);
    height: 100%;
    border-right: 1px solid var(--color-border);
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li a {
    display: block;
    color: var(--color-black);
    text-decoration: none;
    padding: 20px;
    border-bottom: 1px solid var(--color-border);
  }
  
  .sidebar li a:hover {
    font-weight: 700;
    text-decoration: underline;
  }

  @media screen and (max-width: 768px){
    .sidebar {
      display: none;
    }
  }
  