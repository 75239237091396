.navbar-top {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  padding: 10px;
  color: var(--color-black);
}

.navbar-top  .navbar-left, .navbar-top  .max-width-desk {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-top > .max-width-desk {
  padding: 0px var(--var-padding-20);
}

  .navbar-left {
    padding: 10px;
    max-height: 80px;
  }

  .navbar-left svg path {
    fill: var(--color-black);
  }

  .navbar-left a:hover svg path, .navbar-left[style="cursor: pointer;"]:hover svg path {
    fill: var(--color-blue);
  }
  
  .navbar-left img {
    height: 60px;
  }
  
  .navbar-right {
    width: 100%;
    max-width: 320px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-items: center;
    gap: var(--var-gap-20);
  }

  .navbar-right > button.button-delete {
    background-color: var(--color-red);
    outline: none;
    padding: 0;
    width: 40px;
    height: 40px;
    border: 1px solid var(--color-border);
    cursor: pointer;
  }

  .navbar-right h2 {
    color: var(--color-black);
  }

  #root {
    width: 100%;
  }

  .app {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  