:root {
	--color-blue: rgb(31, 105, 224);
	--color-white: #FFFFFF;
	--color-black: #000000;
	--color-gray: #C4C4C4;
	--color-red: #FF0505;
	--color-green: rgb(71, 179, 133);
	--color-orange: #e68c38;

	--color-border: #c4c4c4;
	--color-background-screen-black: rgba(0, 0, 0, 0.3);

	--var-gap-5: 5px;
	--var-gap-10: 10px;
	--var-gap-20: 20px;
	--var-gap-40: 40px;
	--var-padding-5: 5px;
	--var-padding-10: 10px;
	--var-padding-20: 20px;
	--var-padding-40: 40px;

	--font-size-h2: 24px;
	--font-size-h3: 20px;
	--font-size-h4: 18px;
	--font-size-normal-high: 16px;
	--font-size-normal: 14px;

	--var-max-width-desk: 700px;
}

* {
	box-sizing: border-box;
	position: relative;
	font-family: 'Montserrat';
	font-size: var(--font-size-normal);
	font-weight: 400;
}

span {
	display: inline-block;
}

*::-webkit-scrollbar{
	-webkit-appearance: none;
}

*::-webkit-scrollbar:vertical{
	width:10px;
}

*::-webkit-scrollbar-button:increment, html::-webkit-scrollbar-button{
	display: none;
}

*::-webkit-scrollbar:horizontal{
	height: 10px;
}

*::-webkit-scrollbar-thumb{
	background-color: var(--color-scroll);
	border-radius: 20px;
}

*::-webkit-scrollbar-track {
	border-radius: 10px;
}

#screenBlackAll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
	background-color: var(--color-background-screen-black);
	z-index: 99999999;
	backdrop-filter: blur(3px);
}

#carga {
	border: 15px solid #ccc;
	border-top-color: var(--color-blue);

	height: 100px;
	width: 100px;
	border-radius: 100%;
	z-index: 99999999;

	position: fixed;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	-webkit-animation: girar 1.5s linear infinite;
	-o-animation: girar 1.5s linear infinite;
	animation: girar 1.5s linear infinite;
}

@keyframes girar {
	from {transform: rotate(0deg);}
	to {transform:rotate(360deg);}
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row.flex-end {
	justify-content: flex-end;
}

.gap-5 {
	gap: var(--var-gap-5);
}

.gap-10 {
	gap: var(--var-gap-10);
}

.gap-20 {
	gap: var(--var-gap-20);
}

.gap-40 {
	gap: var(--var-gap-40);
}

.padding-20 {
	padding: var(--var-padding-20);
}

.padding-40 {
	padding: var(--var-padding-40);
}

.max-width-desk{
	max-width: var(--var-max-width-desk);
}

@media screen and (max-width: 500px){
    .flex-row {
      flex-wrap: wrap;
    }

	:root {
		--var-gap-20: 15px;
		--var-gap-40: 20px;
		--var-padding-20: 15px;
		--var-padding-40: 20px;

		--font-size-h2: 18px;
		--font-size-h3: 16px;
		--font-size-h4: 15px;
		--font-size-normal-high: 14px;
		--font-size-normal: 13px;
	}
}